import React, { useEffect } from "react";
import { Button, ButtonGroup, Container, FormLabel, Typography } from "@mui/material";
import PropTypes from "prop-types";
const ExtrasIncrementer = ({extras, setExtras, totalDays,insuranceValue}) => {

  const handleIncrement = (index) => {
    const updatedExtras = [...extras];

   

    if(updatedExtras[index].id === 5){
      updatedExtras[index].value = insuranceValue;
    }


    if(updatedExtras[index].totalByDay){
      if (updatedExtras[index].quantity < updatedExtras[index].max) {
        updatedExtras[index].quantity += 1;
        updatedExtras[index].totalValue  = ((updatedExtras[index].quantity  * updatedExtras[index].value) * totalDays > updatedExtras[index].maxValue) ? updatedExtras[index].maxValue : (updatedExtras[index].quantity  * updatedExtras[index].value) * totalDays ;      
        setExtras(updatedExtras);
      }
    }else{
      if (updatedExtras[index].quantity < updatedExtras[index].max) {
        updatedExtras[index].quantity += 1;
        updatedExtras[index].totalValue  = ((updatedExtras[index].quantity  * updatedExtras[index].value) > updatedExtras[index].maxValue) ? updatedExtras[index].maxValue : (updatedExtras[index].quantity  * updatedExtras[index].value);      
        setExtras(updatedExtras);
      }
    }
    
  };

  const handleDecrement = (index) => {
    const updatedExtras = [...extras];
    if (updatedExtras[index].quantity > updatedExtras[index].min) {
      updatedExtras[index].quantity -= 1;
      updatedExtras[index].totalValue  = updatedExtras[index].quantity  * updatedExtras[index].value;
      setExtras(updatedExtras);
    }
  };

  useEffect(() => {
    console.log(extras, "extras")
  }, [extras])
  

  return (
    <div>
      {extras.map((extra, index) => (
        <Container sx={{marginBottom:"10px"}} key={extra.extraName}>
          <FormLabel sx={{marginRight:"10px", color:"black"}}
            ><Typography>{extra.extraName} {extra.value === 0 ? insuranceValue : extra.value}€ por aluguer </Typography></FormLabel>
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
          >
            <Button
              size="small"
              variant="contained"
              onClick={() => handleDecrement(index)}
              disabled={extra.quantity <= extra.min}
            >
              <Typography variant="button" display="block" gutterBottom>
                -
              </Typography>
            </Button>
            <span
              style={{
                width: "20px",
                justifyContent: "center",
                display: "flex",
              }}
            >
              {extra.quantity}
            </span>
            <Button
              size="small"
              variant="contained"
              onClick={() => handleIncrement(index)}
              disabled={extra.quantity >= extra.max}
            >
              <Typography variant="button" display="block" gutterBottom>
                +
              </Typography>
            </Button>
          </ButtonGroup>
        </Container>
      ))}
    </div>
  );
};

ExtrasIncrementer.propTypes = {
  extras: PropTypes.any.isRequired,
  setExtras: PropTypes.func.isRequired,
  totalDays: PropTypes.number.isRequired,
  insuranceValue: PropTypes.number.isRequired
};

export default ExtrasIncrementer;
