import React, {useRef} from "react";
import emailjs from "@emailjs/browser";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Box, Typography, Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import SendIcon from "@mui/icons-material/Send";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from 'react-hot-toast';
const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#202A67",
    },
    terceary: {
      main: "#F4F4F4",
    },
  },
});

function Contacts() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const toastSuccess = () => toast.success(t("emailSent"));

  const sendEmail = (data) => {
    emailjs.send(
      "service_zueyg81",
      "template_px2e3oc",
      data,
      "mE9W1oL9cnf93RpTD"
    );
    reset();
    toastSuccess();
  };


  

  const { t } = useTranslation();
  const form = useRef();
  return (
    <ThemeProvider theme={theme}>
      <Typography
        variant="h4"
        component="div"
        sx={{
          flexGrow: 1,
          color: "primary.main",
          textAlign: "center",
          mt: "1rem",
        }}
      >
        {t("contactUs")}
      </Typography>
      <form ref={form} onSubmit={handleSubmit(sendEmail)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1 },
              width: "80%",
            }}
          >
            <FormControl fullWidth>
              <TextField
                required
                id="fullName"
                label={t("fullName")}
                size="string"
                name={t("fullName")}
                {...register("fullName", {
                  required: t("fullNameIsRequired"),
                  minLength: {
                    value: 3,
                    message: t("fullNameInvalid"),
                  },
                })}
                helperText={errors.fullName ? errors.fullName.message : null}
              />
            </FormControl>
            <FormControl
              sx={{
                width: "50%",
              }}
            >
              <TextField
                required
                id="email"
                label={t("Email")}
                type="email"
                name="email"
                {...register("email", {
                  required: t("emailIsRequired"),
                  pattern: {
                    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                    message: t("emailInvalid"),
                  },
                })}
                helperText={errors.email ? errors.email.message : null}
              />
            </FormControl>
            <FormControl
              sx={{
                width: "50%",
              }}
            >
              <TextField
                required
                id="phoneNumber"
                label={t("phoneNumber")}
                type="number"
                name={t("phoneNumber")}
                {...register("phoneNumber", {
                  required: t("phoneNumberIsRequired"),
                  minLength: {
                    value: 9,
                    message: t("phoneNumberInvalid"),
                  },
                })}
                helperText={
                  errors.phoneNumber ? errors.phoneNumber.message : null
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                required
                id="message"
                label={t("message")}
                multiline
                rows={4}
                name="message"
                {...register("message", {
                  required: t("messageIsRequired"),
                  minLength: {
                    value: 3,
                    message: t("messageInvalid"),
                  },
                })}
                helperText={errors.message ? errors.message.message : null}
              />
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <Button
            type="submit"
            value="Send"
            variant="contained"
            endIcon={<SendIcon />}
            size="large"
          >
            {t("submit")}
          </Button>
        </Box>
      </form>
      <Toaster />
    </ThemeProvider>
  );
}
export default Contacts;
