import React from "react";
import Contacts from "./Contacts";
import "./i18n";
import About from "./About";
import Body from "./Body";
export default class App extends React.Component {
  render() {
    return (
      <>
        <section id="body">
          <Body />
        </section>
        {/* <section id="wip">
          <Wip />
        </section> */}
        <section id="aboutUs">
          <About />
        </section>
        <section id="contacts">
          <Contacts />
        </section>
      </>
    );
  }
}
