import React, { useEffect, useMemo, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  MenuItem,
  Select,
  Button,
  TextField,
  CircularProgress,
  Typography,
  FormHelperText,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import SendIcon from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";
import background from "./Assets/background4.jpg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { DESKS } from "./helpers/Constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#202A67",
    },
    terceary: {
      main: "#F4F4F4",
    },
  },
});

const Body = () => {
  const [isLoading, setIsloading] = useState(false);
  // const [avaiabilities, setAvaiabilities] = useState('');

  const reservationTimeValidation = yup.object({
    loc_pickup: yup.number().required("Required"),
    date_pickup: yup
      .date()
      .required("Required")
      .min(dayjs().add(3, "day"), "Pickup date must be today or later")
      .typeError("Please select a valid date"),
    time_pickup: yup.string().required("Required"),
    loc_return: yup.number().required("Required"),
    date_return: yup.date().required("Required"),
    time_return: yup.string().required("Required"),
  });

  const {
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      loc_pickup: "",
      date_pickup: null,
      time_pickup: null,
      loc_return: "",
      date_return: null,
      time_return: null,
      loc_flight: "",
    },
    resolver: yupResolver(reservationTimeValidation),
    reValidateMode: "onChange",
  });

  const datePickup = getValues("date_pickup");

  const validateDateReturn = useMemo(() => {
    return (dateReturn) => {
      if (dateReturn < datePickup) {
        return "Return date must be after pickup date";
      }
      return true;
    };
  }, [datePickup]);

  const { t } = useTranslation();
  const [width, setWidth] = React.useState(window.innerWidth);


  const styles = {
    background: {
      backgroundImage: `url(${background})`,
      backgroundRepeat: "no-repeat",
      width: "100%",
      backgroundSize: "cover",
      height: width < 900 ? "950px" : "700px",
    },
  };

  const navigate = useNavigate();
  const handleChangePickUpDesk = (event) => {
    setValue("loc_pickup", event.target.value, { shouldValidate: true });
    setValue("loc_return", event.target.value, { shouldValidate: true });
  };

  const handleChangeDropOffDesk = (event) => {
    setValue("loc_return", event.target.value, { shouldValidate: true });
  };

  const handleChangePickUpPlace = (event) => {
    setValue("loc_flight", event.target.value, { shouldValidate: true });
  };

  const handleChangeTimePickup = (time) => {
    setValue("time_pickup", `${time?.$H}:${time.$m}`, { shouldValidate: true });
  };

  const handleChangeTimeReturn = (time) => {
    setValue("time_return", `${time?.$H}:${time.$m}`, { shouldValidate: true });
  };

  const handleChangeDatePickup = (date) => {
    setValue("date_pickup", dayjs(date?.$d).format("YYYY-MM-DD"), {
      shouldValidate: true,
    });
  };

  const handleChangeDateReturn = (date) => {
    setValue("date_return", dayjs(date?.$d).format("YYYY-MM-DD"), {
      shouldValidate: true,
      validate: validateDateReturn,
    });
  };

  const [error, setError] = React.useState(null);


  // const funchal = async (event) => {
  //   setValue("loc_pickup", 41);
  //   setValue("loc_return", 41);
  //   const requestOptionsAvaiabilityFunchal = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     },
  //     body: JSON.stringify(getValues()),
  //   };
    
  //   const avaiabilityFunchal = await fetch(
  //     "https://www.whynotcarrental.com/Backend/vehicleAvaibility.php",
  //     requestOptionsAvaiabilityFunchal
  //   );

  //   console.log(avaiabilityFunchal, "avaiabilityFunchal")
  //   const vehicleAvaibility = await avaiabilityFunchal.json();
  //   setAvaiabilities(...avaiabilities, vehicleAvaibility);
  // }

  // const airport = async (event)=> {
  //   setValue("loc_pickup", 42);
  //   setValue("loc_return", 42);
  //   const requestOptionsAvaiabilityAirport= {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     },
  //     body: JSON.stringify(getValues()),
  //   };
    
  //   const avaiabilityAirport= await fetch(
  //     "https://www.whynotcarrental.com/Backend/vehicleAvaibility.php",
  //     requestOptionsAvaiabilityAirport
  //   );

  //   console.log(avaiabilityAirport, "avaiabilityAirport")
  //   const vehicleAvaibility = await avaiabilityAirport.json();
  //   setAvaiabilities(...avaiabilities, vehicleAvaibility);
  // }

  // const calheta = async (event) => {
  //   setValue("loc_pickup", 47);
  //   setValue("loc_return", 47);
  //   const requestOptionsAvaiabilityCalheta = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     },
  //     body: JSON.stringify(getValues()),
  //   };
    
   
  //     const avaiabilityCalheta = await fetch(
  //       "https://www.whynotcarrental.com/Backend/vehicleAvaibility.php",
  //       requestOptionsAvaiabilityCalheta
  //     );
  
  //     console.log(avaiabilityCalheta, "avaiabilityCalheta")
  //     const vehicleAvaibility = await avaiabilityCalheta.json();
  //   setAvaiabilities(...avaiabilities, vehicleAvaibility);
  // }

  // const norte = async (event) => {
  //   setValue("loc_pickup", 46);
  //   setValue("loc_return", 46);
  //   const requestOptionsAvaiabilityNorte = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     },
  //     body: JSON.stringify(getValues()),
  //   };
    
  //   const avaiabilityNorte = await fetch(
  //     "https://www.whynotcarrental.com/Backend/vehicleAvaibility.php",
  //     requestOptionsAvaiabilityNorte
  //   );

  //   console.log(avaiabilityNorte, "avaiabilityNorte")
  //   const vehicleAvaibility = await avaiabilityNorte.json();
  //   setAvaiabilities(...avaiabilities, vehicleAvaibility);
  // }

  useEffect(() => {
    
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    await trigger();
    // funchal();
    // airport();
    // calheta();
    // norte();
    if (Object.keys(errors).length === 0 && isValid) {
      try {
        setIsloading(true);
        
        console.log(getValues(), "getValues()");
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: JSON.stringify(getValues()),
        };


        const response = await fetch(
          "https://www.whynotcarrental.com/Backend/vehicleAvaibility.php",
          requestOptions
        );

        //console.log(avaiabilities, "avaiabilities");

        const vehicleAvaibility = await response.json();
        setIsloading(false);
        navigate("/reservationCheckout", {
          state: { vehicleAvaibility, reservationDetails: getValues() },
        });
        event.preventDefault();
      } catch (err) {
        setIsloading(false);
        setError("Network error. Please try again later.");
      }
    } else {
      // Form is invalid, display error messages
      Object.entries(errors).forEach(([field, error]) => {
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={styles.background}>
          <form onSubmit={(event) => handleSubmit(event)}>
            <Box
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
                width: "-webkit-fill-available",
              }}
            >
              <FormControl
                fullWidth
                sx={{
                  mt: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "5rem",
                    lg: "5rem",
                    xl: "5rem",
                  },
                  ml: "0.5rem",
                  width: "-webkit-fill-available",
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    color: "terceary.main",
                    textAlign: "left",
                    mt: "1rem",
                  }}
                >
                  {t("deliveryDesk")}
                </Typography>
                <Select
                  required
                  labelId="loc_pickup"
                  id="loc_pickup"
                  value={getValues("loc_pickup")}
                  name="loc_pickup"
                  label={t("deliveryDesk")}
                  onError={(newError) => setError(newError)}
                  onChange={handleChangePickUpDesk}
                  sx={{
                    backgroundColor: "white",
                    mr: "1rem",
                  }}
                >
                  {DESKS.map(({ code, name }, index) => (
                    <MenuItem key={index} value={code}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                <Box sx={{ width: "-webkit-fill-available" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      color: "terceary.main",
                      textAlign: "left",
                      mt: "1rem",
                    }}
                  >
                    {t("deliveryDate")}
                  </Typography>
                  <DatePicker
                    disablePast
                    sx={{ backgroundColor: "white", mt: "1rem", mr: "1rem" }}
                    name="date_pickup"
                    format="DD-MM-YYYY"
                    minDate={dayjs().add(3, "day")}
                    value={dayjs(getValues("date_pickup"))}
                    onChange={handleChangeDatePickup}
                    slotProps={{ textField: { variant: 'outlined' } }}
                    error={!!errors.date_pickup}
                    onError={(newError) => setError(newError)}
                  />
                  <FormHelperText error={!!errors.date_pickup}>
                    {errors.date_pickup?.message}
                  </FormHelperText>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      color: "terceary.main",
                      textAlign: "left",
                      mt: "1rem",
                    }}
                  >
                    {t("deliveryTime")}
                  </Typography>
                  <TimePicker
                    sx={{
                      backgroundColor: "white",
                      mt: "1rem",
                    }}
                    value={dayjs(getValues("time_pickup"))}
                    onChange={handleChangeTimePickup}
                    ampm={false}
                    minTime={dayjs()
                      .set("hour", 8)
                      .startOf("hour")
                      .set("minutes", 30)
                      .startOf("minutes")}
                    slotProps={{ textField: { variant: 'outlined' } }}
                    error={!!errors.time_pickup}
                    onError={(newError) => setError(newError)}
                  />
                  <FormHelperText error={!!errors.time_pickup}>
                    {errors.time_pickup?.message}
                  </FormHelperText>
                </Box>
              </FormControl>
              <FormControl
                sx={{
                  ml: "0.5rem",
                  mt: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "5rem",
                    lg: "5rem",
                    xl: "5rem",
                  },
                  width: "-webkit-fill-available",
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    color: "terceary.main",
                    textAlign: "left",
                    mt: "1rem",
                  }}
                >
                  {t("returnDesk")}
                </Typography>
                <Select
                  required
                  labelId="loc_return"
                  id="loc_return"
                  value={getValues("loc_return")}
                  name="loc_return"
                  label={t("returnDesk")}
                  onChange={handleChangeDropOffDesk}
                  sx={{
                    backgroundColor: "white",
                    mr: "1rem",
                  }}
                >
                  {DESKS.map(({ code, name }, index) => (
                    <MenuItem key={index} value={code}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                <Box sx={{ width: "-webkit-fill-available" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      color: "terceary.main",
                      textAlign: "left",
                      mt: "1rem",
                    }}
                  >
                    {t("returnDate")}
                  </Typography>
                  <DatePicker
                    disablePast
                    sx={{ backgroundColor: "white", mt: "1rem", mr: "1rem" }}
                    name="date_return"
                    format="DD-MM-YYYY"
                    onError={(newError) => setError(newError)}
                    minDate={dayjs(getValues("date_pickup"))}
                    value={dayjs(getValues("date_return"))}
                    onChange={handleChangeDateReturn}
                    slotProps={{ textField: { variant: 'outlined' } }}
                    error={!!errors.date_return}
                  />
                  <FormHelperText error={!!errors.date_return}>
                    {errors.date_return?.message}
                  </FormHelperText>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      color: "terceary.main",
                      textAlign: "left",
                      mt: "1rem",
                    }}
                  >
                    {t("returnTime")}
                  </Typography>
                  <TimePicker
                    value={dayjs(getValues("time_return"))}
                    onChange={handleChangeTimeReturn}
                    sx={{
                      backgroundColor: "white",
                      mt: "1rem",
                    }}
                    ampm={false}
                    onError={(newError) => setError(newError)}
                    slotProps={{ textField: { variant: 'outlined' } }}
                    error={!!errors.time_return}
                  />
                  <FormHelperText error={!!errors.time_return}>
                    {errors.time_return?.message}
                  </FormHelperText>
                </Box>
              </FormControl>
            </Box>
            <Box>
            <FormControl
                sx={{
                  ml: "0.5rem",
                }}
              >
              {getValues("loc_pickup") === 47 ||
              getValues("loc_pickup") === 46 ? (
                <>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      color: "terceary.main",
                      textAlign: "left",
                      mt: "1rem",
                    }}
                  >
                    {t("hotel")}
                  </Typography>
                  <TextField
                    label={t("hotel")}
                    value={getValues("loc_flight")}
                    onChange={handleChangePickUpPlace}
                    helperText={t("hotelDeliveryInfo")}
                    sx={{
                      backgroundColor: "white",
                      mt: "1rem",
                    }}
                  />
                </>
              ) : null}
              {getValues("loc_pickup") === 42 ? (
                <>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      color: "terceary.main",
                      textAlign: "left",
                      mt: "1rem",
                    }}
                  >
                    {t("flight")}
                  </Typography>

                  <TextField
                    label={t("flight")}
                    value={getValues("loc_flight")}
                    onChange={handleChangePickUpPlace}
                    helperText={t("airportDeliveryInfo")}
                    required
                    sx={{
                      backgroundColor: "white",
                      mt: "1rem",
                    }}
                  />
                </>
              ) : null}
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: "1rem",
              }}
            >
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  value="Send"
                  variant="contained"
                  endIcon={<SendIcon />}
                  size="large"
                  disabled={isLoading}
                >
                  <input
                    type="hidden"
                    name="action"
                    value="GetVehAvailRate"
                  ></input>
                  {t("search")}
                </Button>
              )}
            </Box>
          </form>
        </div>
        {/*
      <Container>
        {isRentalTimeChoosed && (
          <Container>
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link smooth to="#body" underline="hover" color="inherit">
                  PERIODO DE ALUGUER
                </Link>
                <Link
                  onClick={() => setWasCarChoosed(false)}
                  underline="hover"
                  color="inherit"
                  aria-current="page"
                >
                  A MINHA VIATURA
                </Link>
                {wasCarChoosed && (
                  <Link underline="hover" color="inherit" aria-current="page">
                    Opcionais
                  </Link>
                )}
              </Breadcrumbs>
            </Stack>
            {wasCarChoosed ? (
              <div id="vehicleChoosed">
                <Optionals vehicleChoosedDetails={vehicleChoosedDetails} />
              </div>
            ) : (
              <div id="myVehicle">
                <ChooseVehicle
                  pickUpDesk={pickUpDesk}
                  dropOffDesk={dropOffDesk}
                  date_pickup={dayjs(date_pickup.$d).format("DD/MM/YYYY")}
                  time_pickup={dayjs(time_pickup.$d).format("HH:mm")}
                  date_return={dayjs(date_return.$d).format("DD/MM/YYYY")}
                  time_return={dayjs(time_return.$d).format("HH:mm")}
                  setWasCarChoosed={setWasCarChoosed}
                  setVehicleChoosedDetails={setVehicleChoosedDetails}
                />
              </div>
            )}
          </Container>
        )}
      </Container>
      */}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default Body;
