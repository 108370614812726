import React from "react";
import "./navbar.css";
import { ReactComponent as Hamburger } from "../../Assets/hamburger.svg";
import Logo  from "../../Assets/logo.png";
import { useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import {LANGUAGES} from '../../helpers/Constants';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const select = {
    marginTop: "-10px",
    backgroundColor: "#F4F4F4"
  }

  const { i18n, t } = useTranslation();

  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };

  let navigate = useNavigate(); 
  const goHome = () =>{ 
    navigate("/home");
  }
  return (
    <nav className="navbar">
      <div className="container">
        <div>
          <img src={Logo} className="logo" alt="logo" onClick={goHome}/>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <Hamburger />
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            <li>
              <Link to="/">{t("home")}</Link>
            </li>
            <li>
              <Link smooth to="#aboutUs">{t("about")}</Link>
            </li>
            <li>
              <Link smooth to="#contacts">{t("contacts")}</Link>
            </li>
            <li>
              <select style={select} onChange={onChangeLang} defaultValue="pt">
                {LANGUAGES.map(({ code, label }) => (
                  <option key={code} value={code}>
                    {t(label)}
                  </option>
                ))}
              </select>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
