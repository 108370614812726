import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Button,
  Card,
  CardMedia,
  Typography,
} from "@mui/material";
import { EXTRAS } from "../helpers/Constants";
import ExtrasIncrementer from "../Components/IncrementDecrementBtn/ExtrasIncrementer";
import dayjs from "dayjs";
import "./reservation.css";
import opelAstra from "../Assets/img_car/detail/18.opel_astra.png";
import peugeot308 from "../Assets/img_car/detail/peugeot308.png";
import { NumberConverter } from "../helpers/numberConverter";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#38AECC",
    },
    danger: "#fa0505",
  },
});

function Optionals({
  vehicleChoosedDetails,
  reservationInfo,
  setWasOptionalsChoosed,
  setVehicleExtras,
  setTotalRentalValue,
}) {
  const { t } = useTranslation();
  const [position, setPosition] = useState("absolute");
  const [totalExtras, setTotalExtras] = useState(0);
  const [totalValue, setTotalValue] = useState(parseFloat(vehicleChoosedDetails.totalPrice));
  const [extraFees, setExtraFees] = useState(EXTRAS);
  const [insuranceValue, setInsuranceValue] = useState(0);

  const handleReservationClientDetails = (extraFees, totalValue) => {
    setVehicleExtras({ extraFees });
    setTotalRentalValue(totalValue);
    setWasOptionalsChoosed(true);
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      if (getWindowSize().innerWidth > 853) {
        setPosition("absolute");
      } else {
        setPosition("sticky");
      }
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // const handleFeesCheckboxChange = (event, fee) => {
  //   console.log(fee, "fee");
  //   const updatedFees = extraFees.map((item) =>
  //     item.Purpose === fee.Purpose
  //       ? { ...item, IncludedInRate: event.target.checked }
  //       : item
  //   );

  //   if (event.target.checked) {
  //     setTotalValue(totalValue + parseFloat(fee.Amount));
  //   } else {
  //     setTotalValue(totalValue - parseFloat(fee.Amount));
  //   }

  //   console.log(totalValue, "totalValue");
  //   setExtraFees(updatedFees);
  // };

  useEffect(() => {
    const insuranceValuePerDay =  (parseFloat(vehicleChoosedDetails.SCDW) + parseFloat(vehicleChoosedDetails.GTW)) / vehicleChoosedDetails.totalDays;
    setInsuranceValue(insuranceValuePerDay);

    const extrasTotal = extraFees.reduce((acc, extra) => {
      if (extra.quantity > 0) {
        const extraTotalValue = extra.totalValue;
        acc += extraTotalValue;
      }
      return acc;
    }, 0);

    setTotalExtras(extrasTotal);
    const total = totalExtras + parseFloat(vehicleChoosedDetails.totalPrice) ;

    setTotalValue(total);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraFees, totalExtras]);

  // const handleEquipmentCheckboxChange = (event, equipment) => {
  //   console.log(equipment, "equipment");
  //   const updatedEquipments = extraEquipments.map((item) =>
  //     item.Equipment.EquipType === equipment.Equipment.EquipType
  //       ? {
  //           ...item,
  //           Charge: { ...item.Charge, IncludedInRate: event.target.checked },
  //         }
  //       : item
  //   );

  //   if (event.target.checked) {
  //     setTotalValue(totalValue + parseFloat(equipment.Charge.Amount));
  //   } else {
  //     setTotalValue(totalValue - parseFloat(equipment.Charge.Amount));
  //   }

  //   console.log(updatedEquipments, "totalValue");
  //   setExtraEquipments(updatedEquipments);
  // };

  // const handleCoverageCheckboxChange = (event, coverage) => {
  //   console.log(coverage, "Coverage");
  //   const updatedCoverages = extraCoverages?.map((item) =>
  //     item.Coverage.CoverageType === coverage.Coverage.CoverageType
  //       ? {
  //           ...item,
  //           Charge: { ...item.Charge, IncludedInRate: event.target.checked },
  //         }
  //       : item
  //   );

  //   if (event.target.checked) {
  //     setTotalValue(totalValue + parseFloat(coverage.Charge.Amount));
  //   } else {
  //     setTotalValue(totalValue - parseFloat(coverage.Charge.Amount));
  //   }

  //   console.log(updatedCoverages, "updatedCoverages");

  //   console.log(totalValue, "totalValue");
  //   setExtraCoverages(updatedCoverages);
  // };

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <div className="left-side">
          <ExtrasIncrementer
          insuranceValue={insuranceValue}
            extras={extraFees}
            setExtras={setExtraFees}
            totalDays={vehicleChoosedDetails.totalDays}
          />
        </div>
        <div className="car-details">
          <Card>
            <Typography
              gutterBottom
              sx={{ backgroundColor: "primary.main" }}
              variant="h5"
              align="center"
              color="white"
              component="div"
            >
              {t("myCar")}
            </Typography>
            <Typography
              gutterBottom
              variant="h5"
              align="center"
              color="primary.main"
              component="div"
            >
              {t("group")} {vehicleChoosedDetails.vehicleCode}
            </Typography>
            <Typography
              gutterBottom
              variant="h6"
              align="center"
              component="div"
            >
              {vehicleChoosedDetails.vehicleModel} {t("orSimiliar")}
            </Typography>
            <CardMedia
              sx={{ margin: "20px", height: 180, width: 300 }}
              image={
                vehicleChoosedDetails.pictureUrl
                  ? process.env.PUBLIC_URL +
                    "img_car/detail/" +
                    vehicleChoosedDetails.pictureUrl
                  : vehicleChoosedDetails.vehicleCode === "CDAV"
                  ? opelAstra
                  : vehicleChoosedDetails.vehicleCode === "CDAD"
                  ? peugeot308
                  : null
              }
              title="CAR"
            />
            <Typography
              sx={{ backgroundColor: "primary.main" }}
              gutterBottom
              variant="h5"
              align="center"
              color="white"
              component="div"
            >
              {t("myReservation")}
            </Typography>
            <>
              <Typography
                gutterBottom
                variant="h6"
                align="center"
                component="div"
              >
                {t("pickUp")}
              </Typography>
              <Typography
                gutterBottom
                variant="body1"
                align="center"
                component="div"
              >
                {reservationInfo?.VehAvailRSCore?.VehRentalCore?.PickUpLocation
                  ?.LocationCode === "41"
                  ? "Balcão Funchal- Rua Ivens Nº12"
                  : reservationInfo?.VehAvailRSCore?.VehRentalCore
                      ?.PickUpLocation?.LocationCode === "42"
                  ? "Balcão Aeroporto da Madeira"
                  : reservationInfo?.VehAvailRSCore?.VehRentalCore
                      ?.PickUpLocation?.LocationCode === "47"
                  ? "Balcão Calheta"
                  : reservationInfo?.VehAvailRSCore?.VehRentalCore
                      ?.PickUpLocation?.LocationCode === "46"
                  ? "Balcão Norte"
                  : "Unknown Location"}
              </Typography>
              <Typography
                gutterBottom
                variant="body1"
                align="center"
                component="div"
              >
                {dayjs(
                  reservationInfo?.VehAvailRSCore?.VehRentalCore?.PickUpDateTime
                ).format("DD/MM/YYYY")}{" "}
                às{" "}
                {dayjs(
                  reservationInfo?.VehAvailRSCore?.VehRentalCore?.PickUpDateTime
                ).format("hh:ss")}
              </Typography>
              <Typography
                gutterBottom
                variant="h6"
                align="center"
                component="div"
              >
                {t("dropOff")}
              </Typography>
              <Typography
                gutterBottom
                variant="h6"
                align="center"
                component="div"
              >
                {reservationInfo?.VehAvailRSCore?.VehRentalCore?.PickUpLocation
                  ?.LocationCode === "41"
                  ? "Balcão Funchal- Rua Ivens Nº12"
                  : reservationInfo?.VehAvailRSCore?.VehRentalCore
                      ?.PickUpLocation?.LocationCode === "42"
                  ? "Balcão Aeroporto da Madeira"
                  : reservationInfo?.VehAvailRSCore?.VehRentalCore
                      ?.PickUpLocation?.LocationCode === "47"
                  ? "Balcão Calheta"
                  : reservationInfo?.VehAvailRSCore?.VehRentalCore
                      ?.PickUpLocation?.LocationCode === "46"
                  ? "Balcão Norte"
                  : "Unknown Location"}
              </Typography>
              <Typography
                gutterBottom
                variant="body1"
                align="center"
                component="div"
              >
                {dayjs(
                  reservationInfo?.VehAvailRSCore?.VehRentalCore?.ReturnDateTime
                ).format("DD/MM/YYYY")}{" "}
                às{" "}
                {dayjs(
                  reservationInfo?.VehAvailRSCore?.VehRentalCore?.ReturnDateTime
                ).format("hh:ss")}
              </Typography>
              <Typography
                gutterBottom
                variant="h6"
                align="center"
                component="div"
              >
                {t("carPerDayValue")}
              </Typography>
              <Typography
                gutterBottom
                variant="body1"
                align="center"
                component="div"
              >
                <NumberConverter number={vehicleChoosedDetails.totalPrice} />€
                {t("per")} {vehicleChoosedDetails.totalDays} {t("days")}
              </Typography>
              <Typography
                sx={{ backgroundColor: "primary.main" }}
                gutterBottom
                variant="h5"
                align="center"
                color="white"
                component="div"
              >
                {t("extrasChoosed")}
              </Typography>
              <TableContainer>
                <Table sx={{ minWidth: 200 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("extra")}</TableCell>
                      <TableCell align="right">{t("cost")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {extraFees?.map(
                        ({
                          index,
                          extraName,
                          quantity,
                          value,
                          totalValue,
                          maxValue,
                        }) => (
                          <>
                            {quantity > 0 ? (
                              <>
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {extraName} X{quantity}
                                  </TableCell>
                                  <TableCell align="right">
                                    {totalValue}€
                                  </TableCell>
                                </TableRow>
                              </>
                            ) : null}
                          </>
                        )
                      )}
                      {/* )
                      )}
                      {extraEquipments?.map((equipment) => (
                        <>
                          {equipment.Charge.IncludedInRate ? (
                            <>
                              <TableRow
                                key={equipment.Equipment.EquipType}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {equipment.Equipment.Description}
                                </TableCell>
                                <TableCell align="right">
                                  {parseFloat(equipment.Charge.Amount)}€
                                </TableCell>
                              </TableRow>
                            </>
                          ) : null}
                        </>
                      ))}
                      {extraCoverages?.map((coverage) => (
                        <>
                          {coverage.Charge.IncludedInRate ? (
                            <>
                              <TableRow
                                key={coverage.Coverage.CoverageType}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {coverage.Coverage.Details._}
                                </TableCell>
                                <TableCell align="right">
                                  {parseFloat(coverage.Charge.Amount)}€
                                </TableCell>
                              </TableRow>
                            </>
                          ) : null}
                        </>
                      ))} */}
                    </>
                    <>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                        {t("totalPrice")}
                        </TableCell>
                        <TableCell align="right">{parseFloat(totalValue)}€</TableCell>
                      </TableRow>
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                onClick={() =>
                  handleReservationClientDetails(extraFees, totalValue)
                }
                variant="contained"
                size="large"
              >
                {t("continue")}
              </Button>
            </>
          </Card>
        </div>
      </div>
    </ThemeProvider>
  );
}

Optionals.defaultProps = {
  vehicleChoosedDetails: PropTypes.object,
  reservationInfo: PropTypes.array,
  setWasOptionalsChoosed: PropTypes.bool,
  setVehicleExtras: PropTypes.object,
  setTotalRentalValue: PropTypes.number,
};

export default Optionals;
