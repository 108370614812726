import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import opelAstra from "../Assets/img_car/detail/18.opel_astra.png";
import peugeot308 from "../Assets/img_car/detail/peugeot308.png";
import peugeot3008 from "../Assets/img_car/detail/peugeot3008.png";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#38AECC",
    },
    danger: "#fa0505",
  },
});

const styles = {
  background: {
    display: "flex",
    gap: "3rem",
    justifyContent: "center",
    flexWrap: "wrap",
  },
};

function ChooseVehicle({
  reservationInfo,
  setWasCarChoosed,
  setVehicleChoosedDetails,
}) {
  const { t } = useTranslation();
  const handleReservationOptionals = (
    vehicleModel,
    vehicleCode,
    fuelType,
    pictureUrl,
    transmissionType,
    doorCount,
    passengerQuantity,
    totalPrice,
    SCDW,
    GTW,
    totalDays,
    extraFees,
    extraEquipments,
    coverages
  ) => {
    setWasCarChoosed(true);
    setVehicleChoosedDetails({
      vehicleModel,
      vehicleCode,
      fuelType,
      pictureUrl,
      transmissionType,
      doorCount,
      passengerQuantity,
      totalPrice,
      SCDW,
      GTW,
      totalDays,
      extraFees,
      extraEquipments,
      coverages,
    });
  };
  const [totalDays, setTotalDays] = useState(0);

  useEffect(() => {
    const timeDifference = Math.abs(
      dayjs(reservationInfo?.VehAvailRSCore?.VehRentalCore?.PickUpDateTime).$d -
        dayjs(reservationInfo?.VehAvailRSCore?.VehRentalCore?.ReturnDateTime).$d
    );
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    setTotalDays(daysDifference);
  }, [reservationInfo]);

  const vehicles =
    reservationInfo?.VehAvailRSCore?.VehVendorAvails?.VehVendorAvail?.VehAvails?.VehAvail?.map(
      (vehicle) => ({
        vehicleModel: vehicle.VehAvailCore.Vehicle.VehMakeModel.Name,
        vehicleCode: vehicle.VehAvailCore.Vehicle.VehMakeModel.Code,
        fuelType: vehicle.VehAvailCore.Vehicle.FuelType,
        pictureUrl: vehicle.VehAvailCore.Vehicle.PictureURL,
        transmissionType: vehicle.VehAvailCore.Vehicle.TransmissionType,
        doorCount: vehicle.VehAvailCore.Vehicle.VehType.DoorCount,
        passengerQuantity: vehicle.VehAvailCore.Vehicle.PassengerQuantity,
        totalPrice: vehicle.VehAvailCore.TotalCharge.RateTotalAmount,
        SCDW: vehicle.VehAvailInfo.PricedCoverages.PricedCoverage[1]?.Charge
          .Amount,
        GTW: vehicle.VehAvailInfo.PricedCoverages.PricedCoverage[2]?.Charge
          .Amount,
        extraFees: vehicle.VehAvailCore.Fees.Fee,
        extraEquipments: vehicle.VehAvailCore.PricedEquips.PricedEquip,
        coverages: vehicle.VehAvailInfo.PricedCoverages.PricedCoverage,
      })
    );

  //(dayjs(pickUpDate?.$d).format("DD/MM/YYYY")

  return (
    <ThemeProvider theme={theme}>
      <>
        <div>
          <Container sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ mr: "10px" }}>
              <Typography variant="h6" component="div">
                {t("pickUp")}
              </Typography>
              <p>
                {t("deliveryDesk")} :{" "}
                {reservationInfo?.VehAvailRSCore?.VehRentalCore?.PickUpLocation
                  ?.LocationCode === "41"
                  ? "Balcão Funchal- Rua Ivens Nº12"
                  : reservationInfo?.VehAvailRSCore?.VehRentalCore
                      ?.PickUpLocation?.LocationCode === "42"
                  ? "Balcão Aeroporto da Madeira"
                  : reservationInfo?.VehAvailRSCore?.VehRentalCore
                      ?.PickUpLocation?.LocationCode === "47"
                  ? "Balcão Calheta"
                  : reservationInfo?.VehAvailRSCore?.VehRentalCore
                      ?.PickUpLocation?.LocationCode === "46"
                  ? "Balcão Norte"
                  : "Unknown Location"}
              </p>
              <p>
                {t("deliveryDate")} :{" "}
                {dayjs(
                  reservationInfo?.VehAvailRSCore?.VehRentalCore?.PickUpDateTime
                ).format("DD/MM/YYYY")}
              </p>
              <p>
                {t("deliveryTime")} :{" "}
                {dayjs(
                  reservationInfo?.VehAvailRSCore?.VehRentalCore?.PickUpDateTime
                ).format("hh:ss")}
              </p>
            </Box>
            <Box>
              <Typography variant="h6" component="div">
                {t("dropOff")}
              </Typography>
              <p>
                {t("returnDesk")} :{" "}
                {reservationInfo?.VehAvailRSCore?.VehRentalCore?.ReturnLocation
                  ?.LocationCode === "41"
                  ? "Balcão Funchal- Rua Ivens Nº12"
                  : reservationInfo?.VehAvailRSCore?.VehRentalCore
                      ?.ReturnLocation?.LocationCode === "42"
                  ? "Balcão Aeroporto da Madeira"
                  : reservationInfo?.VehAvailRSCore?.VehRentalCore
                      ?.ReturnLocation?.LocationCode === "47"
                  ? "Balcão Calheta"
                  : reservationInfo?.VehAvailRSCore?.VehRentalCore
                      ?.ReturnLocation?.LocationCode === "46"
                  ? "Balcão Norte"
                  : "Unknown Location"}
              </p>
              <p>
                {t("returnDate")} :{" "}
                {dayjs(
                  reservationInfo?.VehAvailRSCore?.VehRentalCore?.ReturnDateTime
                ).format("DD/MM/YYYY")}
              </p>
              <p>
                {t("returnTime")} :{" "}
                {dayjs(
                  reservationInfo?.VehAvailRSCore?.VehRentalCore?.ReturnDateTime
                ).format("hh:ss")}
              </p>
            </Box>
          </Container>
        </div>
        <Container sx={{ mt: "10px" }}>
          <Typography
            variant="h4"
            component="div"
            sx={{
              flexGrow: 1,
              color: "primary.main",
              textAlign: "center",
              mt: "1rem",
            }}
          >
            {t("fleet")}
          </Typography>
          <div style={styles.background}>
            {vehicles ? (
              vehicles?.map(
                (
                  {
                    vehicleModel,
                    vehicleCode,
                    fuelType,
                    pictureUrl,
                    transmissionType,
                    doorCount,
                    passengerQuantity,
                    totalPrice,
                    SCDW,
                    GTW,
                    extraFees,
                    extraEquipments,
                    coverages,
                  },
                  index
                ) => (
                  <Card sx={{ maxWidth: 800 }} key={index}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      align="center"
                      color="primary.main"
                      component="div"
                    >
                      {t("group")} {vehicleCode}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      align="center"
                      component="div"
                    >
                      {vehicleModel} {t("orSimilar")}
                    </Typography>
                    <CardMedia
                      component="img"
                      sx={{ minHeight: 220, width: 300 }}
                      image={
                        pictureUrl
                          ? process.env.PUBLIC_URL +
                            "img_car/detail/" +
                            pictureUrl
                          : vehicleCode === "CDAV"
                          ? opelAstra
                          : vehicleCode === "CDAD"
                          ? peugeot308
                          : vehicleCode === "CGAD"
                          ? peugeot3008
                          : null
                      }
                      title="CAR"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        {t("carDetails")}:
                      </Typography>
                      <Typography gutterBottom variant="h7" component="div">
                        <b>{t("carDetails")}</b> {fuelType}
                      </Typography>
                      <Typography gutterBottom variant="h7" component="div">
                        <b>{t("gearbox")}</b>{" "}
                        {[
                          "EDAV",
                          "MDAV",
                          "CTAV",
                          "RVAD",
                          "HDAE",
                          "CDAV",
                          "CDAD",
                          "CGAV",
                          "CGAD",
                          "DDAD",
                          "PDAD",
                        ].includes(vehicleCode)
                          ? t("automatic")
                          : t("manual")}
                      </Typography>
                      <Typography gutterBottom variant="h7" component="div">
                        <b>{t("seats")}</b> {passengerQuantity}
                      </Typography>
                      <Typography gutterBottom variant="h7" component="div">
                        <b>{t("doors")}</b> {doorCount}
                      </Typography>
                      <Typography
                        gutterBottom
                        align="center"
                        color="primary.main"
                        variant="h6"
                        component="div"
                      >
                        <b>{t("from")} </b>{" "}
                        {(
                          Math.round(totalPrice * 100) /
                          100 /
                          totalDays
                        ).toFixed(2)}
                        €/{t("day")}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        sx={{ bottom: "10px" }}
                        variant="contained"
                        size="large"
                        onClick={() =>
                          handleReservationOptionals(
                            vehicleModel,
                            vehicleCode,
                            fuelType,
                            pictureUrl,
                            transmissionType,
                            doorCount,
                            passengerQuantity,
                            totalPrice,
                            SCDW,
                            GTW,
                            totalDays,
                            extraFees,
                            extraEquipments,
                            coverages
                          )
                        }
                      >
                        {t("reserve")}
                      </Button>
                    </CardActions>
                  </Card>
                )
              )
            ) : (
              <p>{t("noCarsAvaiable")}</p>
            )}
          </div>
        </Container>
      </>
    </ThemeProvider>
  );
}

ChooseVehicle.defaultProps = {
  reservationInfo: PropTypes.any,
};

export default ChooseVehicle;
