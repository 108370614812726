import React, { useEffect } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { CircularProgress } from "@mui/material";

const PayPalCheckout = ({
  amount,
  currency,
  description,
  onApprove,
  onError,
}) => {
  const [{ isPending }] = usePayPalScriptReducer();
  useEffect(() => {
    console.log(isPending, "isPending");
  }, [isPending]);

  const ButtonWrapper = () => {
    const [{ isPending }] = usePayPalScriptReducer();

    return (
      <>
        {isPending ? (
          <CircularProgress /> 
        ) : (
          <PayPalButtons
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    description: description,
                    amount: {
                      currency_code: currency,
                      value: amount,
                    },
                  },
                ],
              });
            }}
            onApprove={onApprove}
            onError={onError}
          />
        )}
      </>
    );
  };

  return (
    <>
      <ButtonWrapper />
    </>
  );
};

export default PayPalCheckout;
