export const LANGUAGES = [
  { label: "Portuguese", code: "pt" },
  { label: "English", code: "en" },
];

export const EXTRAS = [
  { id:0 ,extraName: "Baby Seat", max: 4, min:0, value:8, quantity:0, maxValue:64 , totalValue: 0, totalByDay:true},
  { id:1, extraName: "Baby Seat Isofix", max: 4, min:0, value:12,quantity:0, maxValue:96,totalValue:0,totalByDay:true},
  { id:2, extraName: "Booster Seat", max: 4, min:0, value:4,quantity:0, maxValue:32,totalValue:0,totalByDay:true},
  { id:5, extraName: "Full Insurance", max: 1, min:0, value:0,quantity:0,maxValue:10000,totalValue:0, totalByDay:true},
  { id:3, extraName: "Young Driver", max: 9, min:0, value:40,quantity:0, maxValue:200,totalValue:0, totalByDay:false},
  { id:4, extraName: "Additional Driver", max: 8, min:0, value:30,quantity:0,maxValue:120,totalValue:0,totalByDay:false},
  { id:7, extraName: "Out of hours return", max: 1, min:0, value:30,quantity:0,maxValue:10000,totalValue:0,totalByDay:false},
  { id:8, extraName: "Out of hours deliver after 23h00", max: 1, min:0, value:60,quantity:0,maxValue:10000,totalValue:0,totalByDay:false},
];

export function handleQuantityChange(extraIndex, quantity) {
  const extra = EXTRAS[extraIndex];

  if (quantity < extra.min) {
    quantity = extra.min;
  } else if (extra.max && quantity > extra.max) {
    quantity = extra.max;
  }

  EXTRAS[extraIndex].quantity = quantity;
  EXTRAS[extraIndex].totalValue = quantity * extra.value;
}

export const DESKS = [
  { code: 41, name: 'Balcão Funchal- Rua Ivens Nº12'},
  { code: 42, name: 'Balcão Aeroporto da Madeira'},
  { code: 47, name: 'Balcão Calheta'},
  { code: 46, name: 'Balcão Norte'}
];