import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import "./i18n";
import TermsAndConditions from "./TermsAndConditions";
import App from "./app";
import Navbar from "./Components/Navbar/Navbar";
import FooterComponent from "./Components/Footer/FooterComponent";
import ChooseVehicle from "./Reservation/ChooseVehicle";
import ReservationCheckout from "./Reservation/ReservationCheckout";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <Navbar />
      <Routes>
      <Route path="/" element={<App/>} />
      <Route path="/home" element={<App/>} />
      <Route path="/termsAndConditions" element={<TermsAndConditions/>} />
      <Route path="/chooseVehicle" element={<ChooseVehicle/>} />
      <Route path="/reservationCheckout" element={<ReservationCheckout/>} />
      </Routes>
      <FooterComponent />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
